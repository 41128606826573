const pictureFill = require('picturefill');
const flickity = require('flickity');
require('flickity-imagesloaded');

export default {

  init() {
    this.navToggle();
    this.eventListeners();

    $('html').removeClass('no-js').addClass('js');
  },


  navToggle() {
    $('.header__nav-toggle').on('click', function(e) {
      let $toggle = $(this);
      let $menu = $('.header__nav');

      $menu.toggleClass('is-nav-active');
      $('html').toggleClass('is-nav-open');

      if(!$('html').hasClass('is-nav-open')) {
        $('.nav-backdrop').remove();
      } else {
        $(document.createElement('div'))
          .addClass('nav-backdrop fade-in')
          .appendTo($('body'))
          .on('click', function(){
            $toggle.trigger('click');
        });
      }

      $('html').removeClass('is-dropdown-open');
    });

    $('.header__nav .close').on('click', function(e) {
      $('.header__nav-toggle').trigger('click');
    });

  },

  navState() {

    // Only reset on desktop
    if(this.isMobile()) {
      return;
    }

    $('html').removeClass('is-nav-open');
    $('.nav-backdrop').remove();
    $('html').removeClass('is-dropdown-open');
    $('.header__nav').toggleClass('is-nav-active');
  },

  fixedHeader() {

    // Header is only fixed on mobile
    if(!this.isMobile()) {
      $('html').removeClass('is-nav-fixed');
      return;
    }

    var scrollPos = window.scrollY;
    var threshold = $('.site-header').height();

    $('html').toggleClass('is-nav-fixed', (scrollPos >= threshold));
  },

  eventListeners() {
    const common = this;

    // Update on resize
    window.addEventListener('resize', function(e) {
      if (!common.ticking) {
        window.requestAnimationFrame(function() {
          common.navState();
          common.ticking = false;
        });

        common.ticking = true;
      }
    });

    // Update on scroll
    window.addEventListener('scroll', function(e) {
      if (!common.ticking) {
        window.requestAnimationFrame(function() {
          //common.fixedHeader();
          common.ticking = false;
        });

        common.ticking = true;
      }
    });
  },

  isMobile() {
    return window.matchMedia('(max-width: 1023px)').matches;
  }

};
