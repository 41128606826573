export default {

  init() {
    const collapse = this;

    $('[data-toggle="collapse"]').on('click', function(e) {
      let $toggle = $(this);
      let $target = $($toggle.attr('data-target'));
      let element = $target[0]
      let height = element.scrollHeight;
      let expanded = ($target.attr('aria-expanded') === 'true');
      let offset = $('.site-header').height();

      if(expanded) {
        $target.css('max-height', '');
        $target.attr('aria-expanded', 'false');
        $toggle.html('Expand Agenda');
      } else {
        $target.css('max-height', height);
        $target.attr('aria-expanded', 'true');
        $toggle.html('Collapse Agenda');
      }

    });




  }

};
