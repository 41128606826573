export default {

  init() {
    const tabs = this;

    $('.tabs__tablist a').on('click', function(e) {
      e.preventDefault();

      let $tab = $(this);
      let $pane = $($tab.attr('href'));

      if($tab.hasClass('show') || !$pane.length) {
        return;
      }

      $('.tabs__tablist a').attr('aria-selected', 'false');
      $tab.attr('aria-selected', 'true');

      $('.tabs__pane').removeClass('show active');
      $pane.addClass('show active');

      tabs.updateUrl(this.href);
    });

    let target = window.location.hash;

    if($(target).length) {
      $('.tabs__tablist a[href="'+target+'"]').trigger('click');
    }
  },

  updateUrl(url) {
    if(window.history.pushState) {
      window.history.pushState({}, '', url);
    }
  }

};
